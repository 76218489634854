<template>
  <div>
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
      <v-toolbar dense light>
        <v-btn icon tile @click="commands.bold" small :class="checkActive(isActive, 'bold')">
          <v-icon small>fas fa-bold</v-icon>
        </v-btn>
        <v-btn icon tile @click="commands.italic" small :class="checkActive(isActive, 'italic')">
          <v-icon small>fas fa-italic</v-icon>
        </v-btn>
        <v-btn icon tile @click="commands.underline" small :class="checkActive(isActive, 'underline')">
          <v-icon small>fas fa-underline</v-icon>
        </v-btn>
        <v-btn icon tile @click="commands.strike" small :class="checkActive(isActive, 'strike')">
          <v-icon small>fas fa-strikethrough</v-icon>
        </v-btn>
        <v-dialog v-model="linkMenuIsActive" width="400">
          <template v-slot:activator="{ on }">
            <v-btn icon tile v-on="on" @click="showLinkMenu(getMarkAttrs('link'))" small :class="checkActive(isActive, 'link')">
              <v-icon small>fas fa-link</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Add/Edit Link</v-card-title>
            <v-card-text>
              <v-text-field label="URL" v-model="linkUrl" ref="linkInput"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="hideLinkMenu()">Cancel</v-btn>
              <v-btn text @click="setLinkUrl(commands, linkUrl)">Save</v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="setLinkUrl(commands, null)">Remove</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-divider vertical></v-divider>
        <v-btn icon tile @click="commands.heading({ level: 1 })" :class="checkActive(isActive, 'heading', { level: 1 })">
          <v-icon small>fal fa-h1</v-icon>
        </v-btn>
        <v-btn icon tile @click="commands.heading({ level: 2 })" :class="checkActive(isActive, 'heading', { level: 2 })">
          <v-icon small>fal fa-h2</v-icon>
        </v-btn>
        <v-btn icon tile @click="commands.heading({ level: 3 })" :class="checkActive(isActive, 'heading', { level: 3 })">
          <v-icon small>fal fa-h3</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn icon tile @click="commands.bullet_list" :class="checkActive(isActive, 'bullet_list')">
          <v-icon small>fal fa-list-ul</v-icon>
        </v-btn>
        <v-btn icon tile @click="commands.ordered_list" :class="checkActive(isActive, 'ordered_list')">
          <v-icon small>fal fa-list-ol</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-menu v-if="mergeFields.length > 0">
          <template v-slot:activator="{ on: menu }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn icon tile v-on="{ ...tooltip, ...menu }">
                  <v-icon small>fas fa-brackets-curly</v-icon>
                </v-btn>
              </template>
              Merge Placeholder Fields (Name, Banner ID, etc)
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in mergeFields" :key="index" @click="addMerge(item)">
              <v-list-item-title>{{ typeof (item) === 'string' ? item : item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </editor-menu-bar>
    <editor-content :editor="editor" :class="smallEditor ? 'small-editor' : (largeEditor ? 'large-editor' : '')" style="padding:1.5em;background-color: white;" class="elevation-4"></editor-content>
  </div>
</template>
<style>
.ProseMirror {
  min-height: 100px;
  max-height: 600px;
  overflow: auto;
  color: rgba(0,0,0,1);
}
.ProseMirror-focused {
  outline: none;
}
.ProseMirror h1, .ProseMirror h2, .ProseMirror h3 {
  margin-bottom: .3em;
}
.small-editor .ProseMirror {
  max-height: 300px;
}
.large-editor .ProseMirror {
  min-height: 300px;
}
</style>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { HardBreak, Heading, HorizontalRule, OrderedList, BulletList, ListItem, Bold, Code, Italic, Link, Strike, Underline, History } from 'tiptap-extensions'

export default {
  components: {
    EditorContent, EditorMenuBar
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    mergeFields: {
      type: Array,
      default: () => ([])
    },
    smallEditor: {
      type: Boolean,
      default: true
    },
    largeEditor: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    editToggles: [],
    editor: null,
    activeAlignment: 'fas fa-left',
    linkUrl: '',
    linkMenuIsActive: false,
    updating: false
  }),
  watch: {
    value (val) {
      if (this.updating) {
        this.updating = false
        return
      }
      if (val !== this.editor.getHTML()) {
        if (val === '') this.editor.clearContent()
        else this.editor.setContent(val)
      }
    }
  },
  methods: {
    showLinkMenu (attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command.link({ href: url })
      this.hideLinkMenu()
    },
    addMerge (item) {
      let text = (typeof (item) === 'string' ? item : item.label)
      this.editor.commands.code()
      const transaction = this.editor.state.tr.insertText(text)
      this.editor.view.dispatch(transaction)
      this.$nextTick(() => {
        this.editor.commands.code()
        const transaction = this.editor.state.tr.insertText(' ')
        this.editor.view.dispatch(transaction)
      })
    },
    checkActive (isActive, item, param) {
      if (isActive[item](param)) return 'blue lighten-4 blue--text'
      return ''
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History()
      ],
      onUpdate: () => {
        this.updating = true
        this.$emit('input', this.editor.getHTML())
        this.$emit('change', this.editor.getHTML())
      }
    })
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>
